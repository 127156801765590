@font-face {
  font-family: "Gotham-Bold";
  src: url('/fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),  url('/fonts/Gotham-Bold.otf')  format('opentype'),
  url('/fonts/Gotham-Bold.woff') format('woff'), url('/fonts/Gotham-Bold.ttf')  format('truetype'), url('/fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham-Medium";
  src: url("/fonts/Gotham-Medium.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Light';
  src: url('/fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),  url('/fonts/Gotham-Light.otf')  format('opentype'),
  url('/fonts/Gotham-Light.woff') format('woff'), url('/fonts/Gotham-Light.ttf')  format('truetype'), url('/fonts/Gotham-Light.svg#Gotham-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* General Style
--------------------------*/
html,body{
  height:100%;
}
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
}
.no-padding {
  padding: 0 !important;
}
.display-none {
  display: none;
}
.navbar-brand {
  display: block;
  @media (max-width: $tablet-min) {
    text-align: center;
  }
  .logo {
    width: 100%;
    max-width: 180px;
  }
}
.top-bar {
  background-color: #005b1e;
  padding: 6px 0 0;
  p {
    color: white;
    padding-top: 10px;
  }
  .customer-dropdown {
    @media (max-width: $tablet-min) {
      text-align: center;
    }
    .dropdown-menu {
      background-color: #ffffff;
      .dropdown-item {
        font-family: "Gotham-Light", sans-serif;
        color: #8BC540 !important;
        font-weight: 600 !important;
        &:active, &:hover {
          color: #fff !important;
          background-color: #8BC540;
        }
      }
    }
    .btn.dropdown_btn {
      font-weight: 600 !important;
      font-size: 16px;
      background-color: transparent !important;
      border: 0 !important;
      outline: none !important;
      @media (max-width: $mobile-max) {
        font-size: 16px;
      }
    }
  }
  .contact-info {
    text-align: right;
    padding-left: 10px;
    font-size: 14px;
    @media (max-width: $mobile-max) {
      text-align: center;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
  strong {
    font-family: "Gotham-Bold", sans-serif;
  }
}
.menu.navbar-nav {
  width: 100%;
  background-color: #e5e5e5;
  text-align: center !important;
  display: inline-block !important;
  .nav-item {
    font-family: "Gotham-Light", sans-serif;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    padding: 0 35px;
    @media (max-width: $laptop-max) {
      padding: 0 20px;
    }
    @media (max-width: $laptop) {
      padding: 0 10px;
    }
    @media (max-width: $tablet) {
      display: block;
      padding: 0 10px;
      text-align: left;
    }
    .nav-link {
      color: #005b1e !important;
    }
    &.active {
      .nav-link {
        color: #005b1e !important;
      }
    }
  }
}
.slogan {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  padding-right: 0;
  @media (max-width: $mobile-max) {
    margin: 0;
    padding-right: 15px;
  }
  p {
    font-family: "Gotham-Bold", sans-serif;
    color: #005b1e;
    font-size: 18px;
    line-height: 24px;
    padding-top: 10px;
    text-align: right;
    @media (max-width: $mobile-max) {
      text-align: center;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
h1 {
  font-size: 34px;
  line-height: 44px;
  font-family: "Gotham-Light", sans-serif;
  font-weight: 600;
  vertical-align: baseline;
  @media (max-width: $laptop-min) {
    font-size: 28px;
    line-height: 38px;
  }
  @media (max-width: $mobile-max) {
    font-size: 24px;
    line-height: 34px;
  }
}
h2 {
  font-size: 22px;
  line-height: 32px;
  font-family: "Gotham-Bold", sans-serif;
  @media (max-width: $laptop-min) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: $mobile-max) {
    font-size: 15px;
    line-height: 22px;
  }
}
h3 {
  font-size: 18px;
  line-height: 24px;
  font-family: "Gotham-Light", sans-serif;
  font-weight: 600;
  @media (max-width: $laptop-min) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: $mobile-max) {
    font-size: 14px;
    line-height: 20px;
  }
}
h4 {
  font-family: "Gotham-Bold", sans-serif;
  font-size: 20px;
  line-height: 28px;
}
p {
  font-family: "Gotham-Light", sans-serif;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
img {
  max-width: 100%;
}
.navbar-brand {
  margin: 0;
}
.navbar-toggler {
  width: 100%;
  text-align: left;
  border-radius: 0 !important;
  padding: 10px !important;
  line-height: 30px !important;
  margin-top: 0 !important;
  background: #e5e5e5;
  color: #005b1e !important;
  font-weight: bold;
  .navbar-toggler-icon {
    float: right;
  }
}

/* Footer
-----------------------*/
.footer {
  .brand-icons {
    background-color: #e5e5e5;
    @media (max-width: $mobile-max) {
      padding-bottom: 10px;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        float: left;
        width: 14.2%;
        margin-bottom: 10px;
        @media (max-width: $mobile-max) {
          float: none;
          display: inline-block;
          width: auto;
          margin: 5px 10px;
        }
        img {
          height: auto;
        }
        .icon {
          width: 100px;
          padding-top: 10px;
        }
      }
    }
  }
  .footer-menu {
    font-family: "Gotham-Medium", sans-serif;
    background-color: #005b1e;
    color: #ffffff;
    letter-spacing: 1.5px;
    padding-top: 20px;
    padding-bottom: 20px;
    a {
      display: block;
      color: #ffffff;
      font-size: 14px;
      line-height: 22px;
      text-decoration: none;
      &:hover {
        color: #8BC540;
      }
    }
    h5 {
      font-size: 18px;
      line-height: 28px;
      margin-top: 10px;
    }
  }
  .copyright {
    margin: 50px auto 20px;
    color: #B3B3B3;
  }
}
/* Home Page
------------------------*/
.home {
  h1 {
    color: #504D4D;
    margin-top: 50px;
    margin-bottom: 10px;
    @media (max-width: $mobile-max) {
      margin-top: 20px;
    }
  }
  h2 {
    color: #000000;
    margin-bottom: 30px;
    margin-top: 15px;
    &.instruments-names {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  h3 {
    color: #4E4D4F;
    margin-bottom: 50px;
    @media (max-width: $mobile-max) {
      margin-bottom: 20px;
    }
  }
  .golfer-img {
    @media (max-width: $laptop-max) {
      width: 80%;
    }
    @media (max-width: $mobile-max) {
      width: 100%;
    }
  }
  .youtube_icon {
    position: absolute;
    left: -100px;
    top: -100px;
    font-family: "Gotham-Bold", sans-serif;
    font-style: italic;
    font-size: 13px;
    color: #8BC540;
    img {
      width: 120px;
      height: auto;
    }
    @media (max-width: $laptop-max) {
      left: -10px;
    }
    @media (max-width: $laptop-min) {
      top: -80px;
      left: 0;
      img {
        width: 80px;
      }
    }
    @media (max-width: $mobile-max) {
      display: block;
      position: initial;
      margin-bottom: 20px;
    }
  }
  .youtube_arrow {
    position: absolute;
    left: -80px;
    top: 0;
    @media (max-width: $laptop-max) {
      left: 10px;
    }
    @media (max-width: $laptop-min) {
      left: 30px;
      width: 60px;
    }
    @media (max-width: $mobile-max) {
      display: none;
    }
  }
  .green-line {
    border-bottom: 5px solid #8BC540;
    &.img-bottom {
      margin: -1px auto 0;
      border-radius: 15px;
      width: 85%;
      @media (max-width: $laptop-max) {
        width: 75%;
      }
      @media (max-width: $mobile-max) {
        width: 85%;
      }
    }
  }
  .sell-btn {
    font-size: 24px;
    line-height: 34px;
    max-width: 100%;
    padding: 10px 20px;
    font-family: "Gotham-Bold", sans-serif;
    margin-bottom: 20px;
    color: white;
    background-color: #005b1e;
    border: 1px solid #005b1e;
    border-radius: 10px;
    &:hover {
      background-color: #8BC540;
      border-color: #8BC540;
    }
    @media (max-width: $mobile-max) {
      font-size: 20px;
      line-height: 30px;
      padding: 10px 15px;
    }
  }
  .customer-details {
    text-align: center;
    h2 {
      font-size: 36px;
      line-height: 46px;
      color: #333333;
      font-family: "Gotham-Bold", sans-serif;
      margin-bottom: 10px;
      @media (max-width: $tablet) {
        font-size: 28px;
        line-height: 38px;
      }
    }
    h3 {
      color: #333333;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
      @media (max-width: $tablet) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .bottom-line {
      border-bottom: 2px solid #B3B3B3;
    }
  }
  .as-seen-on {
    h5 {
      font-size: 14px;
      font-family: "Gotham-Bold", sans-serif;
      color: #B3B3B3;
    }
    img {
      width: 100%;
    }
  }
  .sell-instrument-banner {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #7AD7F0;
    text-align: center;
    color: #000;
    .wrapper {
      z-index: 999;
    }
    &:before {
      content: '';
      background: url(/images/give-box-balls.png) 0 0 no-repeat;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-size: contain;
      @media (max-width: $tablet) {
        display: none;
      }
    }
    &:after {
      content: '';
      background: url(/images/take-money.png) 100% 0 no-repeat;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin-top: 50px;
      @media (max-width: $tablet) {
        display: none;
      }
    }
    img {
      width: 100%;
    }
    .give-instrument {
      margin-left: -25px;
    }
    .take-money {
      margin-right: -25px;
      margin-top: 45%;
    }
    h2 {
      color: #000;
      font-size: 32px;
      line-height: 42px;
      font-family: 'Gotham-Light', sans-serif;
      font-weight: bold;
      letter-spacing: 1px;
      margin-top: 10px;
      margin-bottom: 15px;
      @media (max-width: $tablet) {
        font-size: 26px;
        line-height: 36px;
      }
    }
    h3 {
      color: #000;
      font-size: 18px;
      line-height: 28px;
      font-family: 'Gotham-Light', sans-serif;
      font-weight: 400;
      margin-bottom: 20px;
      @media (max-width: $tablet) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .btn-border {
      display: inline-block;
      font-size: 20px;
      line-height: 30px;
      padding: 15px 40px;
      font-family: "Gotham-Bold", sans-serif;
      color: #ffffff;
      background-color: #005b1e;
      border: 5px solid #ffffff;
      border-radius: 10px;
      text-decoration: none;
      @media (max-width: $tablet) {
        font-size: 18px;
        line-height: 26px;
        padding: 10px 20px;
      }
    }
  }
}
h1 {
  &.title {
    font-size: 32px;
    font-family: "Gotham-Bold", sans-serif;
    color: #005b1e;
    margin-bottom: 15px;
  }
}
h4 {
  &.sub-title {
    margin-top: 25px;
    margin-bottom: 15px;
  }
}
.about {
  padding-top: 50px;
  .about-block-wrapper {
    margin-top: 20px;
  }
  .about_block {
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    background: #e6e6e6;
    .heading {
      font-family: "Gotham-Bold", sans-serif;
      font-size: 16px;
      color: #005b1e;
      margin-top: 10px;
    }
    .sub-heading {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
.info-page {
  padding: 50px 0;
}
.progress {
  height: 10px;
  border-radius: 16px;
  margin-bottom: 65px;
  @media (max-width: $mobile-max) {
    margin-bottom: 35px;
  }
  .progress-bar {
    border-radius: 16px;
    background-color: #005b1e;
  }
}
.sell {
  h1 {
    color: #504D4D;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 20px;
    @media (max-width: $laptop-min) {
      font-size: 24px;
      line-height: 34px;
    }
    @media (max-width: $mobile-max) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .box-item {
    width: 22%;
    height: 150px;
    display: inline-block;
    background-color: #F2F2F2;
    border: 1px solid #b5b0b0;
    border-radius: 10px;
    margin: 20px 1%;
    vertical-align: middle;
    &:hover {
      border: 1px solid #005b1e;
    }
    @media (max-width: $tablet-max) {
      height: 120px;
      width: 30%;
    }
    @media (max-width: $mobile-max) {
      width: 47%;
    }
    label, a {
      height: 100%;
      cursor: pointer;
      display: block;
      margin: 0;
      img {
        width: 75%;
        margin-bottom: 10px;
      }
      span {
        color: #005b1e;
        font-family: "Gotham-Light", sans-serif;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        vertical-align: middle;
        position: relative;
        float: left;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: $tablet-max) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
    input {
      position: absolute;
      visibility: hidden;
    }
  }
  .sell-instrument-form-b {
    label {
      color: #005b1e;
      font-family: "Gotham-Light", sans-serif;
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      @media (max-width: $tablet-max) {
        font-size: 20px;
        line-height: 30px;
      }
      &.error {
        margin-top: 10px;
        color: #ff0000;
        font-size: 14px;
        line-height: 20px;
      }
    }
    h1 {
      color: #005b1e;
      margin-bottom: 5px;
      font-family: Anton, Arial;
      text-transform: uppercase;
    }
    .btn {
      font-size: 26px;
      line-height: 32px;
      max-width: 100%;
      padding: 8px 20px;
      font-family: "Gotham-Bold", sans-serif;
      color: white;
      background-color: #005b1e;
      border: 1px solid #005b1e;
      border-radius: 10px;
      box-shadow: none;
      &:hover {
        background-color: #8BC540;
        border-color: #8BC540;
      }
      @media (max-width: $tablet-max) {
        font-size: 24px;
        line-height: 32px;
        margin-top: 10px;
      }
    }
    .form-box {
      background-color: #e5e5e5;
      border: 1px solid #b5b0b0;
      border-radius: 10px;
      padding: 15px 20px 10px;
      margin-bottom: 25px;
      .description {
        font-size: 24px;
        line-height: 34px;
        color: #000;
        font-weight: bold;
      }
      .form-group {
        margin-bottom: 1rem;
      }
      .form-control {
        border-color: #ffffff;
        box-shadow: none;
        font-size: 16px;
        line-height: 18px;
        padding: 6px 10px;
        border-radius: 10px;
        @media (max-width: $tablet-max) {
          font-size: 18px;
          line-height: 26px;
        }
        &.error {
          border-color: #ff0000;
        }
      }
      .btn-file {
        cursor: pointer;
        color: #4E4D4F;
        font-size: 20px;
        line-height: 30px;
        font-family: "Gotham-Light", sans-serif;
        font-weight: bold;
        background: linear-gradient(#fff, #D8D8D7);
        border: 1px solid #D8D8D7;
        padding: 6px 15px;
        border-radius: 10px;
        margin: 20px 20px 20px 0;
      }

    }
  }
  .sell-instrument-from {
    label {
      color: #005b1e;
      font-family: "Gotham-Light", sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 34px;
      @media (max-width: $tablet-max) {
        font-size: 20px;
        line-height: 30px;
      }
      &.error {
        margin-top: 10px;
        color: #ff0000;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .form-box {
      background-color: #F2F2F2;
      border: 1px solid #b5b0b0;
      border-radius: 10px;
      padding: 15px 20px 10px;
      .description {
        font-size: 24px;
        line-height: 34px;
        color: #000;
        font-weight: bold;
      }
      .form-group {
        margin-bottom: 1rem;
      }
      .form-control {
        border-color: #ffffff;
        box-shadow: none;
        font-size: 18px;
        line-height: 24px;
        padding: 6px 10px;
        border-radius: 10px;
        @media (max-width: $tablet-max) {
          font-size: 18px;
          line-height: 26px;
        }
        &.error {
          border-color: #ff0000;
        }
      }
      .btn {
        font-size: 26px;
        line-height: 32px;
        max-width: 100%;
        padding: 8px 20px;
        font-family: "Gotham-Bold", sans-serif;
        color: white;
        background-color: #005b1e;
        border: 1px solid #005b1e;
        border-radius: 10px;
        box-shadow: none;
        &:hover {
          background-color: #8BC540;
          border-color: #8BC540;
        }
        @media (max-width: $tablet-max) {
          font-size: 24px;
          line-height: 32px;
          margin-top: 10px;
        }
      }
      
      .btn-file {
        cursor: pointer;
        color: #4E4D4F;
        font-size: 20px;
        line-height: 30px;
        font-family: "Gotham-Light", sans-serif;
        font-weight: bold;
        background: linear-gradient(#fff, #D8D8D7);
        border: 1px solid #D8D8D7;
        padding: 6px 15px;
        border-radius: 10px;
        margin: 20px 20px 20px 0;
      }

      .scale-title {
        color: #005b1e;
        font-family: "Gotham-Light", sans-serif;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        margin-top: 30px;
        margin-bottom: 10px;
      }
      .scale {
        color: #504D4D;
        font-size: 24px;
        line-height: 36px;
        font-family: "Gotham-Light", sans-serif;
        font-weight: bold;
      }
      .condition-scale {
        margin-top: 30px;
        span {
          float: left;
          width: 10%;
          text-align: center;
          label {
            display: block;
          }
        }
      }
      input[type="radio"] {
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
      .radio-group {
        label {
          color: #000000;
          margin-right: 20px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
  .select-brand {
    .box-item {
      @media (max-width: $tablet-max) {
        height: 150px;
      }
      label {
        img {
          width: 70%;
          display: block;
          margin: 0 auto 10px;
        }
        span {
          font-size: 18px;
          line-height: 28px;
          @media (max-width: $laptop-min) {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
.thank-you {
  min-height: 450px;
}
.card {
  font-family: "Gotham-Light", sans-serif;
  font-weight: bold;
  color: #005b1e;
  .card-header {
    background-color: #e5e5e5;
  }
}
.btn-primary {
  color: #fff;
  background-color: #0b6db5;
  border-color: #0b6db5;
}
.btn-link {
  font-family: "Gotham-Light", sans-serif;
  font-weight: bold;
  color: #8BC540;
  background-color: transparent;
}
.login-page {
  padding: 85px 0;
}
.register-page {
  padding: 55px 0;
}
.form-control {
  box-shadow: none !important;
}
.question-answer {
  td {
    vertical-align: top;
  }
  .question {
    margin-right: 10px;
  }
  .answer {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
a.removeFileInput {
  color: #fff;
  background: #000;
  border-radius: 50%;
  padding: 1px 5px 3px;
  text-decoration: none;
  font-size: 10px;
  line-height: 10px;
}
.team {
  h2, p {
    color: #005b1e;
  }
  p {
    font-family: "Gotham-Light", sans-serif;
    font-weight: bold;
  }
}
.home{
  padding: 0px !important;
  // text-align: center;
}